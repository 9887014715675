import { create } from 'zustand';
import type { UserState } from '@/types/types';
import { createJSONStorage, persist } from 'zustand/middleware';

export interface UserStoreState {
	user: UserState | null;
	setUser: (user: UserState) => void;
	clearUser: () => void;
}

export const useUserStore = create(
	persist<UserStoreState>(
		(set) => ({
			user: {
				roleID: 0,
				username: '',
				warehouseID: 0,
			},
			setUser: (user: UserState) => set({ user }),
			clearUser: () =>
				set({
					user: {
						roleID: 0,
						username: '',
						warehouseID: 0,
					},
				}),
		}),
		{
			name: 'user-store',
			storage: createJSONStorage(() => localStorage),
		},
	),
);
