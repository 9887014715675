import React from 'react';
import {
	FormControl,
	FormField,
	FormItem,
	FormLabel,
	FormMessage,
} from '../ui/form';
import { Input } from '../ui/input';
import { useFormContext } from 'react-hook-form';

type Props = {
	name: string;
	type: 'text' | 'email' | 'password' | 'number' | 'tel' | 'url' | 'search';
	label: string;
};

function FormFieldNoDesc({ name, type, label }: Props) {
	const { control } = useFormContext();
	return (
		<>
			<FormField
				name={name}
				control={control}
				render={({ field }) => (
					<FormItem>
						<FormLabel>{label}</FormLabel>
						<FormControl>
							<Input
								{...field}
								type={type}
								value={field.value || ''}
							/>
						</FormControl>
						<FormMessage />
					</FormItem>
				)}
			/>
		</>
	);
}

export default FormFieldNoDesc;
